<template>
  <div>
    <b-overlay :show="false" rounded="sm">
      <div class="row mb-1">
        <!-- <div class="col-6">
          <card-welcome v-if="user" :name="user.full_name" :avatarUrl="user.avatar_url" />
        </div> -->

        <div class="d-flex justify-content-between align-items-center w-100 p-1 mb-2">
          <div>
            <button-count :disabled="user && !user.manage_transactions.includes('recharges')"
              icon="import-icon"
              @click="changeTransactionType('recharge')"
              :active="transactionType === 'recharge'"
              :count="totalRechargesCount"
              :text="'Recargas'" class="mr-2"/>

            <button-count :disabled="user && !user.manage_transactions.includes('withdrawals')"
              @click="changeTransactionType('withdrawal')"
              :active="transactionType === 'withdrawal'"
              :count="totalWithdrawalsCount"
              :text="'Retiros'"
              class="mr-2"
            />

            <balance-badge :amount="transactionType === 'recharge' ? completedRechargesBalance : completedWithdrawalsBalance" />
          </div>
          <div>
            <drop-down-filters @change="changeFilter"/>
          </div>
        </div>
        <div class="d-flex" style="width:100%">
          <div style="width: 20%">
            <card-count :icon="iconCard" color="#ffd14f" title="Pendientes" :count="transactionType === 'recharge' ? pendingRechargesCount : pendingWithdrawalsCount" :recharges="pendingRechargesCount" :withdrawals="pendingWithdrawalsCount" />
          </div>

          <div style="width: 20%">
            <card-count :icon="iconCard" color="#6bf182" title="Pre-Aprobadas" :count="preApprovedCount" :recharges="completedRechargesCount" :withdrawals="completedWithdrawalsCount"/>
          </div>

          <div style="width: 20%">
            <card-count :icon="iconCard" color="#6bf182" title="Aprobadas" :count="transactionType === 'recharge' ? completedRechargesCount : completedWithdrawalsCount" :recharges="completedRechargesCount" :withdrawals="completedWithdrawalsCount"/>
          </div>

          <div style="width: 20%">
            <card-count :icon="iconCard" color="#ff7a7a" title="Rechazadas" :count="transactionType === 'recharge' ? rejectedRechargesCount : rejectedWithdrawalsCount" :recharges="rejectedRechargesCount" :withdrawals="rejectedWithdrawalsCount"/>
          </div>

          <div style="width: 20%">
            <card-count :icon="iconCard" color="#3d52ff" title="Total" :count="transactionType === 'recharge' ? totalRechargesCount : totalWithdrawalsCount" :recharges="totalRechargesCount" :withdrawals="totalWithdrawalsCount" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <recharges-dashboard :filters="filters" v-show="transactionType === 'recharge'" />
          <withdrawals-dashboard :filters="filters" v-show="transactionType === 'withdrawal'" />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import CardStatistics from './Dashboard/CardStatistics'
import TopTenClients from './Dashboard/TopTenClients'
import OrdersInCourse from './Dashboard/OrdersInCourse'
import EarningsByMonthYear from './Dashboard/EarningsByMonthYear'
import TopTenDistributors from './Dashboard/TopTenDistributors'
import ComparativeEarnings from './Dashboard/ComparativeEarnings'
import { $themeColors } from '@themeConfig'
import DatePicker from 'vue2-datepicker'
import { BOverlay, BCard, BButton } from 'bootstrap-vue'
import numberMixins from '@/mixins/numberMixins'
import CardCount from '@/components/CardCount'
import CardWelcome from '@/components/CardWelcome'
import RechargesDashboard from '@/components/RechargesDashboard'
import WithdrawalsDashboard from '@/components/WithdrawalsDashboard'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import DropDownFilters from '@/components/DropDownFilters'
import ButtonCount from '@/components/ButtonCount'
import BalanceBadge from '@/components/BalanceBadge'

export default {
  components: {
    ComparativeEarnings,
    BCard,
    TopTenDistributors,
    EarningsByMonthYear,
    OrdersInCourse,
    TopTenClients,
    CardStatistics,
    BOverlay,
    DatePicker,
    CardCount,
    CardWelcome,
    BButton,
    RechargesDashboard,
    WithdrawalsDashboard,
    DropDownFilters,
    ButtonCount,
    BalanceBadge,
  },
  mixins: [numberMixins],
  data() {
    return {
      rangePicker: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      ],
      transactionType: 'recharge',
      filters: {
        dates: [
          moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        ]
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      pendingCount: (state) => state.dashboard.pendingCount,
      completedCount: (state) => state.dashboard.completedCount,
      rejectedCount: (state) => state.dashboard.rejectedCount,
      pendingRechargesCount: (state) => state.dashboard.pendingRechargesCount,
      completedRechargesCount: (state) => state.dashboard.completedRechargesCount,
      rejectedRechargesCount: (state) => state.dashboard.rejectedRechargesCount,
      pendingWithdrawalsCount: (state) => state.dashboard.pendingWithdrawalsCount,
      completedWithdrawalsCount: (state) => state.dashboard.completedWithdrawalsCount,
      rejectedWithdrawalsCount: (state) => state.dashboard.rejectedWithdrawalsCount,
      preApprovedCount: (state) => state.dashboard.preApprovedRechargesCount,
      completedRechargesBalance: (state) => state.dashboard.completedRechargesBalance,
      completedWithdrawalsBalance: (state) => state.dashboard.completedWithdrawalsBalance,
      totalRechargesCount: (state) => state.dashboard.totalRechargesCount,
      totalWithdrawalsCount: (state) => state.dashboard.totalWithdrawalsCount,
      iconCard() {
        if (this.transactionType === 'recharge') {
          return 'import-icon'
        } else if (this.transactionType === 'withdrawal') {
          return 'export-icon'
        }
      },
      ...mapGetters({
        totalTransactions: 'dashboard/totalTransactions',
        // totalRechargesCount: 'dashboard/totalRechargesCount',
        // totalWithdrawalsCount: 'dashboard/totalWithdrawalsCount',
      }),
    }),
  },
  watch: {
    rangePicker() {
      if (this.rangePicker.length > 1) {
        this.getDashboard()
      }
    },
    // listen deep user changes
    user: {
      handler() {
        // console.log(this.user)
        // rechargetType from value user.manage_transactions
      },
      deep: true,
    },
  },
  methods: {
    changeFilter(range) {
      this.filters.dates = range
      this.getDashboard(range)
    },
    changeTransactionType(type) {
      this.transactionType = type
    },
    async getDashboard(dates = null) {
      try {
        this.$store.dispatch('dashboard/getDashboardInfo', {
          dates
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
  created() {
    this.getDashboard()
  },
  mounted() {
    if (this.user && this.user.manage_transactions.includes('recharges')) {
      this.transactionType = 'recharge'
    } else if (
      this.user &&
      this.user.manage_transactions.includes('withdrawals')
    ) {
      this.transactionType = 'withdrawal'
    }
  },
}
</script>

<style lang="scss" scoped>
.active {
  background-color: #3d52ff !important;
  color: #fff !important;
}

.gap-25px {
  gap: 25px;
}
</style>

<template>
  <b-card v-if="data" class="card-transaction" no-body>
    <b-card-header>
      <div>
        <b-card-title>Mejores clientes</b-card-title>
      </div>
      <feather-icon icon="AwardIcon" size="18" class="cursor-pointer" />
    </b-card-header>

    <b-card-body>
      <div v-if="data.length" class="text-center">
        <feather-icon icon="AwardIcon" size="40" class="cursor-pointer mb-1" />
        <h3>{{ numberFormat(data[0].total_orders) }}</h3>
        <p>Cliente: {{ data[0].client.full_name }}</p>
      </div>
      <div
        v-for="client in data"
        :key="client.user_id"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-primary">
              <feather-icon size="18" icon="UserIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ client.client.full_name }}
            </h6>
            <small>{{ client.client.email }}</small>
          </b-media-body>
        </b-media>
        <div class="font-weight-bolder text-success">
          {{ numberFormat(client.total_orders) }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import numberMixins from '@/mixins/numberMixins'

export default {
  mixins: [numberMixins],
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {},
}
</script>

<template>
    <b-card style="width:100%">
        <div class="d-flex justify-content-between">
            <h2>Balance y transacciones</h2>

            <div v-if="!versionExpanded" class="d-flex align-items-start">
                <b-button-group size="sm">
                    <b-button :class="['btn-group', {'active-btn-group': currentTypeDate === 'day' }]" @click="changeDates('day')">En el dia</b-button>
                    <b-button :class="['btn-group', {'active-btn-group': currentTypeDate === 'month' }]" @click="changeDates('month')">En el mes</b-button>
                </b-button-group>
                <maximize-icon color="#ccc" class="ml-3" v-b-modal.modal-xl/>
            </div>
        </div>
        <p>Resumen por metodo de pago</p>

        <div>
        <div class="mt-3">
            <button-count
              icon="import-icon"
              @click="changeTransactionType('recharge')"
              :active="transactionType === 'recharge'"
              :count="countRecharges"
              :text="'Recargas'" class="mr-2"
              :disabled="loadingPaymentMethodsDashboard"
            />

            <button-count
              @click="changeTransactionType('withdrawal')"
              :active="transactionType === 'withdrawal'"
              :count="countWithdrawals"
              :text="'Retiros'"
              class="mr-2"
              :disabled="loadingPaymentMethodsDashboard"
            />

            <dropdown-filters v-show="versionExpanded" @change="changeFilter"/>
        </div>
        <div v-if="loadingPaymentMethodsDashboard" class="text-center text-primary my-5 pt-3">
          <b-spinner type="grow" class="align-middle"></b-spinner>
          <strong class="ml-1">Cargando</strong>
        </div>
        <div v-else class="mt-3">
            <b-card no-body class="mb-1" v-for="pba in paymentMethods" :key="pba.id">
                <div class="d-flex align-items-center justify-content-between p-1" v-b-toggle="'accordion-'+pba.id">
                    <div class="d-flex">
                        <b-avatar
                            v-if="pba.icon_url"
                            variant="primary"
                            :src="pba.icon_url"
                            class="mr-1"
                            size="sm"
                        ></b-avatar>
                        <div>
                            <strong>{{ pba.name }} - {{ transactionType === 'recharge' ? pba.totals.recharges.count : pba.totals.withdrawals.count }}</strong>
                        </div>
                    </div>

                    <div>
                        <div class="success-badge">
                            $ {{ numberFormat(transactionType === 'recharge' ? pba.totals.recharges.amount : pba.totals.withdrawals.amount) }}
                        </div>
                        <p>{{ pba.format_code }} {{ numberFormat(transactionType === 'recharge' ? pba.totals.recharges.amount_local : pba.totals.withdrawals.amount_local) }}</p>
                    </div>
                </div>
                <b-collapse :id="'accordion-'+pba.id" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="pl-3">
                    <b-card no-body class="mb-1" v-for="b in pba.banks" :key="b.id">
                      <div class="d-flex align-items-center pt-1 justify-content-between">
                        <div v-b-toggle="'accordion-'+b.id">
                            <div class="d-flex">
                                <b-avatar
                                v-if="b.icon_url"
                                variant="primary"
                                :src="b.icon_url"
                                class="mr-1"
                                size="sm"
                                ></b-avatar>
                                <div>
                                    <strong>{{b.name }} - {{ transactionType === 'recharge' ? b.recharges.count : b.withdrawals.count }}</strong>
                                </div>
                            </div>
                        </div>
                        <div>
                            <b>$ {{ numberFormat(transactionType === 'recharge' ? b.recharges.amount : b.withdrawals.amount) }}</b>
                            <p>{{ pba.format_code }} {{ numberFormat(transactionType === 'recharge' ? b.recharges.amount_local : b.withdrawals.amount_local) }}</p>
                        </div>
                      </div>
                      <!-- <b-collapse :id="'accordion-'+b.id" accordion="other-accordion" role="tabpanel">
                        <b-card-body>
                d
                        </b-card-body>
                      </b-collapse> -->
                    </b-card>

                  </b-card-body>
                </b-collapse>
              </b-card>
        </div>
        </div>

          <b-modal @hide="closedModal()" body-class="p-0" centered  id="modal-xl" size="lg" class="p-0 m-0" hide-header hide-footer>
            <div style="position:absolute;right: 1rem; top: 1rem;z-index: 4;cursor: pointer"><feather-icon
            icon="XIcon"
            size="30"
            @click="closeModal()"
          /></div>
            <balance-and-transactions-dashboard :versionExpanded="true"/>
          </b-modal>

    </b-card>
</template>
<script>
import { BCard, BButtonGroup, BButton, BCardBody, BCollapse, BAvatar, VBToggle, BSpinner, BModal } from 'bootstrap-vue'
import { mapState } from 'vuex'
import MaximizeIcon from '@/components/icons/Maximize'
import ButtonCount from '@/components/ButtonCount'
import numberMixins from '@/mixins/numberMixins'
import moment from 'moment'
import BalanceAndTransactionsDashboard from '@/components/BalanceAndTransactionsDashboard'
import DropdownFilters from '@/components/DropDownFilters'

export default {
    name: 'BalanceAndTransactionsDashboard',
    props: {
        versionExpanded: {
            type: Boolean,
            default: false,
        },
    },
    directives: {
        'b-toggle': VBToggle,
    },
    mixins: [numberMixins],
    components: {
        BCard,
        BButtonGroup,
        BButton,
        MaximizeIcon,
        ButtonCount,
        BCardBody,
        BCollapse,
        BAvatar,
        BSpinner,
        BModal,
        BalanceAndTransactionsDashboard,
        DropdownFilters,
    },
    data() {
        return {
            transactionType: 'recharge',
            dates: [
                moment().startOf('day').toDate(),
                moment().endOf('day').toDate(),
            ],
            currentTypeDate: 'day'
        }
    },
    computed: {
        ...mapState({
            paymentMethods: state => state.dashboard.paymentMethodsDashboardInfo.paymentMethods,
            countRecharges: state => state.dashboard.paymentMethodsDashboardInfo.countRecharges,
            countWithdrawals: state => state.dashboard.paymentMethodsDashboardInfo.countWithdrawals,
            loadingPaymentMethodsDashboard: state => state.dashboard.loadingPaymentMethodsDashboard,
            user: state => state.auth.user,
        }),
    },
    methods: {
        closedModal() {
            console.log('closed')
            this.transactionType = 'recharge'
            this.changeDates('day')
            this.getBalanceAndTransactions()
        },
        closeModal() {
            this.$bvModal.hide('modal-xl')
        },
        async changeFilter(filter) {
            await this.$store.dispatch('dashboard/getPaymentMethodCountsDashboard', {
                type: this.transactionType,
                dates: filter,
            })
        },
        changeDates(type) {
            this.currentTypeDate = type
            if (type === 'day') {
                this.dates = [
                    moment().startOf('day').toDate(),
                    moment().endOf('day').toDate(),
                ]
            } else {
                this.dates = [
                    moment().startOf('month').toDate(),
                    moment().endOf('month').toDate(),
                ]
            }
            this.getBalanceAndTransactions()
        },
        changeTransactionType(type) {
            this.transactionType = type
        },
        async getBalanceAndTransactions() {
            await this.$store.dispatch('dashboard/getPaymentMethodCountsDashboard', {
                type: this.transactionType,
                dates: this.dates,
            })
        },
    },
    mounted() {
       this.getBalanceAndTransactions()
    },
}
</script>
<style scoped>
.btn-group {
    background: #f0efef !important;
    border: none !important;
    color: #7a7777 !important;
}

.active-btn-group {
    background: rgba(17, 39, 235, 0.185) !important;
    border: none !important;
    color: #3d52ff !important;
}

h2 {
    font-size: 1.2rem;
    font-weight: 600;
}

.success-badge {
    background-color: #daf7e1;
    color: #249900;
    padding: 0.3rem 0.5rem;
    border-radius: 1rem;
    font-weight: bold;
}
</style>

<template>
  <b-card v-if="data" class="card-transaction" no-body>
    <b-card-header>
      <div>
        <b-card-title>Mejores empresas</b-card-title>
      </div>
      <feather-icon icon="AwardIcon" size="18" class="cursor-pointer" />
    </b-card-header>

    <b-card-body>
      <div v-if="data.length" class="text-center">
        <feather-icon icon="AwardIcon" size="40" class="cursor-pointer mb-1" />
        <h3>
          {{ data[0].quantity_orders }}
          {{
            data[0].quantity_orders > 1
              ? 'ordenes finalizadas'
              : 'orden finalizada'
          }}
        </h3>
        <p>Repartidor: {{ data[0].user.full_name }}</p>
      </div>
      <div v-for="r in data" :key="r.user_id" class="transaction-item">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-primary">
              <feather-icon size="18" icon="UserIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ r.user.full_name }}
            </h6>
            <small>{{ r.user.phone }}</small>
          </b-media-body>
        </b-media>
        <div class="font-weight-bolder text-success">
          {{ r.quantity_orders }}
          {{ r.quantity_orders > 1 ? 'ordenes' : 'orden' }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import numberMixins from '@/mixins/numberMixins'

export default {
  mixins: [numberMixins],
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {},
}
</script>

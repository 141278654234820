<template>
  <div>
    <home-admin v-if="user.role.code === 'superadmin'" />
    <home-gestor v-else />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import HomeAdmin from './HomeAdmin'
import HomeGestor from './HomeGestor'

export default {
  components: {
    HomeAdmin,
    HomeGestor,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {},
  methods: {},
}
</script>

<style lang="scss" scoped>
</style>

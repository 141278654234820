<template>
  <b-card
    v-if="tableData"
    title="Transacciones en curso"
    class="card-company-table"
  >
    <b-table
      v-if="tableData.length"
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- code -->
      <template #cell(code)="data">
        <router-link
          :to="{ name: 'order-detail', params: { id: data.item.id } }"
        >
          # {{ data.item.code }}
        </router-link>
      </template>

      <!-- client -->
      <template #cell(client)="data">
        <router-link
          :to="{ name: 'order-detail', params: { id: data.item.client.id } }"
        >
          {{ data.item.client.full_name }}
        </router-link>
      </template>

      <!-- repartidor -->
      <template #cell(distributor)="data">
        <b>
          {{
            data.item.distributor ? data.item.distributor.user.full_name : 'S/R'
          }}
        </b>
      </template>

      <!-- total -->
      <template #cell(total)="data">
        {{ numberFormat(data.item.total) }}
      </template>

      <!-- estatus -->
      <template #cell(order_latest_status)="data">
        <b-badge
          :variant="getColorStatus(data.item.order_latest_status.code)"
          class="badge-glow"
        >
          {{ data.item.order_latest_status.name }}
        </b-badge>
      </template>
    </b-table>
    <h3 v-else class="text-center">No hay transacciones en curso</h3>
  </b-card>
</template>

<script>
import { BCard, BTable, BAvatar, BImg, BBadge } from 'bootstrap-vue'

import numberMixins from '@/mixins/numberMixins'

export default {
  components: {
    BBadge,
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  mixins: [numberMixins],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: 'code', label: '#' },
        { key: 'client', label: 'Cliente' },
        { key: 'distributor', label: 'Repartidor' },
        { key: 'total', label: 'Total' },
        { key: 'order_latest_status', label: 'Estatus' },
      ],
    }
  },
  methods: {
    getColorStatus(code_status) {
      if (code_status == 'in_process') return 'info'
      if (code_status == 'in_route') return 'warning'
      if (code_status == 'in_destiny') return 'warning'
      if (code_status == 'delivered') return 'success'
      if (code_status == 'finished') return 'primary'
      if (code_status == 'cancelled') return 'danger'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>

<template>
<svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 9.75C20.59 9.75 20.25 9.41 20.25 9V3.75H15C14.59 3.75 14.25 3.41 14.25 3C14.25 2.59 14.59 2.25 15 2.25H21C21.41 2.25 21.75 2.59 21.75 3V9C21.75 9.41 21.41 9.75 21 9.75Z" :fill="color"/>
<path d="M9 21.75H3C2.59 21.75 2.25 21.41 2.25 21V15C2.25 14.59 2.59 14.25 3 14.25C3.41 14.25 3.75 14.59 3.75 15V20.25H9C9.41 20.25 9.75 20.59 9.75 21C9.75 21.41 9.41 21.75 9 21.75Z" :fill="color"/>
<path d="M13.4995 11.2499C13.3095 11.2499 13.1195 11.1799 12.9695 11.0299C12.6795 10.7399 12.6795 10.2599 12.9695 9.96994L20.4695 2.46994C20.7595 2.17994 21.2395 2.17994 21.5295 2.46994C21.8195 2.75994 21.8195 3.23994 21.5295 3.52994L14.0295 11.0299C13.8795 11.1799 13.6895 11.2499 13.4995 11.2499Z" :fill="color"/>
<path d="M2.99945 21.7499C2.80945 21.7499 2.61945 21.6799 2.46945 21.5299C2.17945 21.2399 2.17945 20.7599 2.46945 20.4699L9.96945 12.9699C10.2595 12.6799 10.7395 12.6799 11.0295 12.9699C11.3195 13.2599 11.3195 13.7399 11.0295 14.0299L3.52945 21.5299C3.37945 21.6799 3.18945 21.7499 2.99945 21.7499Z" :fill="color"/>
</svg>
</template>
<script>
export default {
    props: {
        size: {
            type: [ String, Number ],
            default: 24,
        },
        color: {
            type: String,
            default: "white",
        },
    },
}
</script>
<template>
  <div class="row">
    <div class="col">
      <b-card class="text-center">
        <b-avatar size="48" variant="light-danger" id="mensajeria">
          <feather-icon size="24" icon="DollarSignIcon" />
        </b-avatar>
        <b-tooltip target="mensajeria" placement="top">
          <span>Monto total de deuda en servicios de mensajeria</span>
        </b-tooltip>
        <div class="truncate mt-1">
          <h2 class="mb-25 font-weight-bolder">
            {{ numberFormat(due_services) }}
          </h2>
          <span>Retiros</span>
        </div>
      </b-card>
    </div>
    <div class="col">
      <b-card class="text-center">
        <b-avatar size="48" variant="light-danger" id="compras">
          <feather-icon size="24" icon="DollarSignIcon" />
        </b-avatar>
        <b-tooltip target="compras" placement="top">
          <span>Monto total de deuda en compras</span>
        </b-tooltip>
        <div class="truncate mt-1">
          <h2 class="mb-25 font-weight-bolder">
            {{ numberFormat(due_addons) }}
          </h2>
          <span>Recargas</span>
        </div>
      </b-card>
    </div>
    <div class="col">
      <b-card class="text-center">
        <b-avatar size="48" variant="light-danger" id="total">
          <feather-icon size="24" icon="DollarSignIcon" />
        </b-avatar>
        <b-tooltip target="total" placement="top">
          <span>Monto total de deuda</span>
        </b-tooltip>
        <div class="truncate mt-1">
          <h2 class="mb-25 font-weight-bolder">
            {{ numberFormat(due_total) }}
          </h2>
          <span>Envios de dinero</span>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BTooltip,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue'

import numberMixins from '@/mixins/numberMixins'

export default {
  components: {
    BTooltip,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardTitle,
  },
  mixins: [numberMixins],
  props: {
    due_services: {
      default: 0,
    },
    due_addons: {
      default: 0,
    },
    due_total: {
      default: 0,
    },
  },
  methods: {},
}
</script>

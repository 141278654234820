<template>
    <b-card style="width: 100%">
        <div class="d-flex justify-content-between">
            <h2>Balance de transacciones</h2>

            <div>
                <b-button-group size="sm">
                    <b-button :class="['btn-group', {'active-btn-group': currentType === 'week' }]" @click="changeValues('week')">Diario</b-button>
                    <b-button :class="['btn-group', {'active-btn-group': currentType === 'month' }]" @click="changeValues('month')">Mensual</b-button>
                    <b-button :class="['btn-group', {'active-btn-group': currentType === 'year' }]" @click="changeValues('year')">Anual</b-button>
                </b-button-group>
            </div>
        </div>
        <p>Resumen de transacciones</p>

        <div v-if="loadingTransactionsDashboard" class="text-center text-primary my-5 pt-3">
          <b-spinner type="grow" class="align-middle"></b-spinner>
          <strong class="ml-1">Cargando</strong>
        </div>

        <div v-else>
        <div class="mt-2">
            <div class="d-flex justify-content-around align-items-end" style="height: 300px">
                <div v-for="v in currentValues" :key="v.label" style="height: 300px" class="parent-bar" >
                    <div :class="['bar', {'active-bar': v.label === currentBar.label }]" @click="selectBar(v)" :style="{height: calculateHeight(v.count)}"></div>
                    <div class="text-center">{{ v.label }}</div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-4">
                    <div class="card-amount">
                        <div class="d-flex align-items-center">
                            <span class="icon success">
                                <import-icon size="18"/>
                            </span>
                            <p>Recargas</p>
                        </div>
                        <h3 style="width:100%">$ {{ numberFormat(totals.recharges) }}</h3>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card-amount">
                        <div class="d-flex align-items-center">
                            <span class="icon danger">
                                <export-icon size="18"/>
                            </span>
                            <p>Retiros</p>
                        </div>
                        <h3 style="width:100%">$ {{ numberFormat(totals.withdrawals) }}</h3>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card-amount">
                        <div class="d-flex align-items-center">
                            <span class="icon warning">
                                <send-icon size="18"/>
                            </span>
                            <p>Transferencias</p>
                        </div>
                        <h3 style="width:100%">$ {{ numberFormat(totals.transfers) }}</h3>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </b-card>
</template>
<script>
import { BCard, BButton, BButtonGroup, BSpinner } from 'bootstrap-vue'
import ExportIcon from '@/components/icons/Export'
import ImportIcon from '@/components/icons/Import'
import SendIcon from '@/components/icons/Send'
import moment from 'moment'
import { mapState } from 'vuex'
import numberMixins from '@/mixins/numberMixins'

export default {
    components: {
        BCard,
        BButton,
        ExportIcon,
        ImportIcon,
        SendIcon,
        BButtonGroup,
        BSpinner,
    },
    mixins: [numberMixins],
    data() {
        return {
            dates: [],
            currentValues: [],
            totals: {
                recharges: 0,
                withdrawals: 0,
                transfers: 0,
            },
            currentBar: null,
            currentType: 'week',
        }
    },
    computed: {
        ...mapState({
            transactions: state => state.dashboard.transactionsDashboardInfo,
            amountRecharges: state => state.dashboard.transactionsDashboardInfo.amountRecharges,
            amountWithdrawals: state => state.dashboard.transactionsDashboardInfo.amountWithdrawals,
            amountTransfers: state => state.dashboard.transactionsDashboardInfo.amountTransfers,
            loadingTransactionsDashboard: state => state.dashboard.loadingTransactionsDashboard,
        }),
    },
    methods: {
        selectBar(value) {
            this.totals = {
                recharges: value.recharges,
                withdrawals: value.withdrawals,
                transfers: value.transfers,
            }
            this.currentBar = value
        },
        async getItems(dates = null, type = 'week') {
            return await this.$store.dispatch('dashboard/getTransactionsDashboard', {
                dates: dates,
                type: type,
            })
        },
        async changeValues(type) {
            this.currentType = type
            switch (type) {
                case 'week':
                    this.dates = [
                        moment().startOf('week').toDate(),
                        moment().endOf('week').toDate(),
                    ]
                    break
                case 'year':
                    this.dates = [
                        // get 1st day of the year
                        moment().startOf('year').toDate(),
                        // get last day of the year
                        moment().endOf('year').toDate(),
                    ]
                    break
                case 'month':
                    this.dates = [
                        moment().startOf('year').toDate(),
                        moment().endOf('year').toDate(),
                    ]
                    break
            }
            this.currentValues = await this.getItems(this.dates, type)
            this.currentValues = this.currentValues[type]

            if(this.currentValues.length > 0) {
                this.selectBar(this.currentValues[0])
            }

            this.currentBar = this.currentValues[0]
        },
        calculateHeight(value) {
            // calculate the height of the bar in percentage based on the total value
            const total = this.currentValues.reduce((acc, curr) => acc + curr.count, 0)

            return `${Math.trunc((value / total) * 100)}%`
        },
    },
    mounted() {
        this.changeValues('week')
        this.getItems()
    },
}
</script>
<style scoped>
.parent-bar {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.bar {
    width: 25px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.bar:hover {
    background-color: rgb(61, 82, 255);
}

.icon {
    height: 30px;
    width: 30px;
    background-color: #e0e0e0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 16px;
}

.card-amount {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
}

.card-amount p {
    font-weight: bold;
}

.success {
    background-color: #6bf182;
}

.danger {
    background-color: #f44336;
}

.warning {
    background-color: #ff9800;
}

.active-bar {
    background-color: rgb(61, 82, 255);
}

.btn-group {
    background: #f0efef !important;
    border: none !important;
    color: #7a7777 !important;
}

.active-btn-group {
    background: rgba(17, 39, 235, 0.185) !important;
    border: none !important;
    color: #3d52ff !important;
}

h2 {
    font-size: 1.2rem;
    font-weight: 600;
}
</style>

<template>
  <div>
    <b-overlay :show="false" rounded="sm">
      <div class="row mb-1">
        <div v-if="user && (user.email === 'rosalino101085@p2p.com' || user.email === 'admin@p2p.com')" class="col-3 mb-2">
          <b-overlay :show="loadingExport" rounded spinner-small>
            <b-button :disabled="loadingExport" variant="primary" @click="exportDatabase()">
              Exportar datos de usuarios
            </b-button>
          </b-overlay>
        </div>
        <!-- <div class="col-6">
          <card-welcome v-if="user" :name="user.full_name" :avatarUrl="user.avatar_url" />
        </div> -->

        <!-- <div class="d-flex justify-content-between align-items-center w-100 p-1 mb-2">
          <div>
            <drop-down-filters @change="changeFilter"/>
          </div>
        </div> -->
        <div class="d-flex" style="width:100%">
          <div style="width: 20%">
            <card-count icon="people-icon" color="#ffd14f" title="Usuarios" :count="totalUsers" />
          </div>

          <div style="width: 20%">
            <card-count icon="check-list-icon" color="#6bf182" title="Transacciones" :count="totalTransactions" />
          </div>

          <div style="width: 20%">
            <card-count icon="wallet-icon" color="#6bf182" title="Balance en usuarios" :count="numberFormat(usersBalance)" />
          </div>

          <div style="width: 20%">
            <card-count icon="wallet-icon" color="#ff7a7a" title="Balance usuarios VIP" :count="numberFormat(usersVipBalance)" />
          </div>

          <div style="width: 20%">
            <card-count icon="wallet-icon" color="#3d52ff" title="Balance Total" :count="numberFormat(totalBalance)" />
          </div>
        </div>
      </div>

      <div class="row mt-3">
          <div class="col-md-6 d-flex">
            <balance-of-transactions />
          </div>
          <div class="col-md-6 d-flex">
            <balance-and-transactions-dashboard />
          </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <recent-activity-dashboard />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import API from '@/libs/axios'
import CardStatistics from './Dashboard/CardStatistics'
import TopTenClients from './Dashboard/TopTenClients'
import OrdersInCourse from './Dashboard/OrdersInCourse'
import EarningsByMonthYear from './Dashboard/EarningsByMonthYear'
import TopTenDistributors from './Dashboard/TopTenDistributors'
import ComparativeEarnings from './Dashboard/ComparativeEarnings'
import DatePicker from 'vue2-datepicker'
import { BOverlay, BCard, BButton } from 'bootstrap-vue'
import numberMixins from '@/mixins/numberMixins'
import CardCount from '@/components/CardCount'
import CardWelcome from '@/components/CardWelcome'
import RechargesDashboard from '@/components/RechargesDashboard'
import WithdrawalsDashboard from '@/components/WithdrawalsDashboard'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import DropDownFilters from '@/components/DropDownFilters'
import ButtonCount from '@/components/ButtonCount'
import BalanceBadge from '@/components/BalanceBadge'
import BalanceAndTransactionsDashboard from '@/components/BalanceAndTransactionsDashboard'
import BalanceOfTransactions from '@/components/BalanceOfTransactions.vue'
import RecentActivityDashboard from '@/components/RecentActivityDashboard.vue'
import { downloadBlob } from '@/helpers/utils'

export default {
  components: {
    ComparativeEarnings,
    BCard,
    TopTenDistributors,
    EarningsByMonthYear,
    OrdersInCourse,
    TopTenClients,
    CardStatistics,
    BOverlay,
    DatePicker,
    CardCount,
    CardWelcome,
    BButton,
    RechargesDashboard,
    WithdrawalsDashboard,
    DropDownFilters,
    ButtonCount,
    BalanceBadge,
    BalanceAndTransactionsDashboard,
    BalanceOfTransactions,
    RecentActivityDashboard,
  },
  mixins: [numberMixins],
  data() {
    return {
      loadingExport: false,
      rangePicker: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      ],
      transactionType: 'recharge',
      filters: {
        dates: [
          moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        ]
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      totalUsers: (state) => state.dashboard.totalUsers,
      totalTransactions: (state) => state.dashboard.totalTransactions,
      usersBalance: (state) => state.dashboard.usersBalance,
      usersVipBalance: (state) => state.dashboard.usersVipBalance,
      totalBalance: (state) => state.dashboard.totalBalance,
    }),
  },
  watch: {
    rangePicker() {
      if (this.rangePicker.length > 1) {
        this.getDashboard()
      }
    },
    // listen deep user changes
    user: {
      handler() {
        // console.log(this.user)
        // rechargetType from value user.manage_transactions
      },
      deep: true,
    },
  },
  methods: {
    async exportDatabase() {
      try {
        this.loadingExport = true
        const response = await API.get('export-data', {
          responseType: 'blob',
        })
        if (response.data) {
          return downloadBlob(response.data, 'export-data.xlsx')
        }
      } catch (error) {
        alert('Error al exportar datos')
      } finally {
        this.loadingExport = false
      }
    },
    changeFilter(range) {
      console.log('jose', range)
      this.filters.dates = range
      this.getDashboard(range)
    },
    changeTransactionType(type) {
      this.transactionType = type
    },
    async getDashboard(dates = null) {
      try {
        this.$store.dispatch('dashboard/getDashboardInfo', {
          dates
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
  created() {
    this.getDashboard()
  },
  mounted() {
    if (this.user && this.user.manage_transactions.includes('recharges')) {
      this.transactionType = 'recharge'
    } else if (
      this.user &&
      this.user.manage_transactions.includes('withdrawals')
    ) {
      this.transactionType = 'withdrawal'
    }
  },
}
</script>

<style lang="scss" scoped>
.active {
  background-color: #3d52ff !important;
  color: #fff !important;
}

.gap-25px {
  gap: 25px;
}
</style>

<template>
    <b-card>
        <div class="d-flex justify-content-between">
            <h2>Actividad reciente</h2>

            <div>
                <drop-down-filters @change="changeFilter"/>
            </div>
        </div>

        <div v-if="loading" class="text-center text-primary my-5 pt-3">
          <b-spinner type="grow" class="align-middle"></b-spinner>
          <strong class="ml-1">Cargando</strong>
        </div>

        <div class="mt-5 px-5" v-else>
            <div v-for="a in items" :key="a.id" class="custom-card-log position-relative">
                <span class="dot"></span>
                <p class="d-flex justify-content-between">
                   <span><b>{{ a.causer.full_name }}</b> -  {{ a.description }}</span> <span>{{ dateFormat(a.created_at, 'DD MMMM YYYY, hh:mm:ss A') }}</span>
                </p>
                <div>
                    <user-badge v-if="a.subject_type === 'App\\Models\\User'" :user="a.subject" :disabled="true"/>
                    <user-badge v-if="a.subject_type === 'App\\Models\\Recharge'" :user="a.subject.user" :disabled="true"/>
                </div>
            </div>
        </div>

        <b-row v-show="items.length > 0 && !loading">
            <b-col md="2" sm="12" class="d-none d-sm-block">
                <b-form-group class="mb-0">
                <label class="d-inline-block mr-50">Por página</label>
                <b-form-select id="perPageSelect" :value="perPage" size="sm" :options="pageOptions" @change="perPageChange"
                    style="width: auto" />
                </b-form-group>
            </b-col>
            <b-col v-show="totalRows > perPage" md="10" sm="12">
                <b-pagination striped hover responsive first-number last-number :value="currentPage" :total-rows="totalRows"
                :per-page="perPage" align="right" size="sm" class="my-0" @change="changePage" />
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
import { BCard, BButton, BButtonGroup, BRow, BCol, BPagination, BFormSelect, BFormGroup, BSpinner } from 'bootstrap-vue'
import ExportIcon from '@/components/icons/Export'
import ImportIcon from '@/components/icons/Import'
import SendIcon from '@/components/icons/Send'
import DropDownFilters from '@/components/DropDownFilters'
import { mapState } from 'vuex'
import UserBadge from '@/components/UserBadge'
import moment from 'moment'

export default {
    components: {
        BCard,
        BButton,
        ExportIcon,
        ImportIcon,
        SendIcon,
        BButtonGroup,
        DropDownFilters,
        UserBadge,
        BRow,
        BCol,
        BPagination,
        BFormSelect,
        BFormGroup,
        BSpinner,
    },
    computed: {
        ...mapState({
            items: (state) => state.activityLog.items,
            perPage: (state) => state.activityLog.perPage,
            currentPage: (state) => state.activityLog.currentPage,
            totalRows: (state) => state.activityLog.totalRows,
            pageOptions: (state) => state.activityLog.pageOptions,
            searchTerm: (state) => state.activityLog.searchTerm,
            loading: (state) => state.activityLog.loading,
        }),
    },
    methods: {
        changeFilter(filter) {
            console.log(filter)
            this.$store.dispatch('activityLog/filtersChange', {
                dates: filter
            })
            this.getItems()
        },
        getItems(page = 1) {
            this.$store.dispatch('activityLog/get', {
                currentPage: page,
            })
        },
        dateFormat(date, format = 'DD/MM/YYYY') {
            return moment(date).format(format)
        },
        changePage(page) {
            this.getItems(page)
        },
        perPageChange(perPage) {
            this.$store.dispatch('activityLog/perPageChange', perPage)
            this.getItems()
        },
    },
    mounted() {
        this.getItems()
    },
}
</script>
<style scoped>

.custom-card-log {
    padding-left: 6rem;
    border-left: 2px solid #f1f0f0;
    padding-bottom: 4rem;
}

/* remove border left last element */
.custom-card-log:last-child {
    border-left: none;
}

.custom-card-log p {
    margin-bottom: 0px;
}

.custom-card-log div {
    margin-top: 10px;
}

.dot {
    height: 16px;
    width: 16px;
    background-color: #72df9c;
    border-radius: 50%;
    position: absolute;
    left: -8px;
}

h2 {
    font-size: 1.2rem;
    font-weight: 600;
}

</style>
